import { moduleRequest } from '../../../utils/request'
import vue from '../../../main'
import tool from '../../../utils/tool'

const request1 = moduleRequest(`/auth/`)
const request2 = moduleRequest(`/oa/market-schedules/`)
const request3 = moduleRequest(`/oa/market-shift-config/`)
// const request4 = moduleRequest(`/oa/market-day-clock-recode/`)
const request5 = moduleRequest(`/oa/market/`)

export default {
  // 账号密码登录
  async newLogin() {
    try {
      // 从缓存中获取账号和密码
      const account = vue.$cache.local.get('phone')
      const password = vue.$cache.local.get('password')
      // 构建登录请求数据
      const loginData = { account, password }
      // 发起登录请求
      const loginResult = await request1('doLogin', loginData, 'post', false)

      // 如果登录成功，继续获取权限信息
      if (loginResult) {
        try {
          // 发起获取权限的请求
          const permissionsResult = await request1('permissions', { userLoginToken: loginResult }, 'post', false)
          // 如果权限请求失败，这里可以添加额外的处理逻辑
          if (!permissionsResult) {
            vue.$message({
              type: 'error',
              message: '权鉴获取有误！',
              duration: 2000,
              showClose: true
            })
            // 可以根据实际需求处理权限请求失败的情况
          }
          // 如果权限请求成功，这里不需要显式返回 true，因为外层已经处理了登录成功的情况
          let time = Date.now()
          tool.data.set('TOKEN_TIME', time)
          tool.data.set('KEY_NEW_DEPT_TYPE', permissionsResult.deptOrgType)
          tool.data.set('KEY_NEW_USERID', permissionsResult.id)
          tool.data.set('KEY_NEW_DEPT_NAME', permissionsResult.orgName)
          tool.data.set('KEY_NEW_DEPT_ID', permissionsResult.orgId)
        } catch (error) {
          // 显示错误消息
          vue.$message({
            type: 'error',
            message: error.msg || error,
            duration: 2000,
            showClose: true
          })
          // 抛出错误，确保登录流程在权限请求失败时也被视为失败
          throw error
        }
      }

      // 返回登录结果，登录成功时 loginResult 应该是具体的登录信息
      return loginResult
    } catch (error) {
      // 处理登录过程中出现的错误
      vue.$message({
        type: 'error',
        message: error.msg || error,
        duration: 2000,
        showClose: true
      })
      // 根据实际情况，可以选择返回 null 或 false 表示登录失败
      return null
    }
  },
  // 查询店铺排班信息
  marketSchedulesPosQuery(data) {
    return request2('pos-query', data, 'post', false)
  },
  // 保存店铺排班信息
  marketSchedulesPosSave(data) {
    return request2('pos-save', data, 'post', false)
  },
  // 发起编辑排班申请
  marketSchedulesEditApply(data) {
    return request2('schedules-edit-apply', data, 'post', false)
  },
  // 查询申请编辑记录
  marketSchedulesQuery(data) {
    return request2('query-edit-apply-recode', data, 'post', false)
  },
  // 查询店铺编制店员信息
  marketRelationUser(data) {
    return request2('query-market-relation-user', data, 'post', false)
  },
  // 查询店铺班次可选信息
  marketQueryMarketShiftInfo(data) {
    return request3('query-market-shift-info', data, 'post', false)
  },
  // 店铺打卡记录
  marketQueryQueryList(data) {
    return request2('query-oa-market-attendance-today-info-by-market-id', data, 'post', false)
  },
  // 店铺打卡二维码
  getAttendanceCode(data) {
    return request2('get-oa-market-attendance-qr-code-list', data, 'post', false)
  },
  // 店铺按月查询考勤汇总
  queryMarketAttendanceMonthly(data) {
    return request2('query-oa-market-attendance-monthly-info-by-market-id', data, 'post', false)
  },
  //店员管理用户列表接口
  queryStoreManaegrOaUser(data) {
    return request5('query-store-manager-oa-frontend-users-by-dept', data, 'post', false)
  },
  //正式员工用户明细
  QueryStoreManagerOaFrontendUserDetail(data){
    return request5('query-store-manager-oa-frontend-user-detail', data, 'post',false)
  },
  //试工拒绝
  hrReviewUserTrialWorkRefuse(data) {
    return request5('review-user-trial-work-refuse', data, 'post', false)
  },
  //试工通过
  hrReviewUserTrialWorkAgree(data) {
    return request5('review-user-trial-work-agree', data, 'post', false)
  },
  //离职审批通过
  reviewUserResignationAgreeByStoreManager(data) {
    return request5('review-user-resignation-agree-by-store-manager', data, 'post', false)
  },
  //离职审批拒绝
  reviewUserResignationRejectByStoreManager(data) {
    return request5('review-user-resignation-reject-by-store-manager', data, 'post', false)
  },
  //离职详情
  queryUserResignationApplyRecordDetail(data) {
    return request5('query-frontend-user-resignation-apply-record-detail', data, 'post', false)
  },
  //处理应聘登记信息
  reviewOaUserApplyJobRecord(data) {
    return request5('review-oa-user-apply-job-record', data, 'post', false)
  },
  //离职申请撤回
  reviewUserResignationRevocation(data) {
    return request5('review-user-resignation-revocation', data, 'post', false)
  },
  //确认离职
  postUserResignationAgreementActions(data) {
    return request5('post-user-resignation-agreement-actions', data, 'post', false)
  },
  //查询应聘登记列表
  queryOaUserApplyJobRecordList(data) {
    return request5('query-oa-user-apply-job-record-list', data, 'post', false)
  },
  //保存用户明细
  saveStoreManagerOaFrontendUserDetail(data){
    return request5('save-store-manager-oa-frontend-user-detail',data,'post',false)
  },
  //试工员工用户明细
  queryFrontendProbationUserDetail(data){
    return request5('query-store-manager-oa-frontend-probation-user-detail',data,'post',false)
  },
  //获取应聘登记二维码
  getOaUserApplyJobQrCode(data){
    return request5('get-oa-user-apply-job-qr-code',data,'post',false)
  },
  //人员变动轨迹
  queryUserShopAssistantWorkRecodeList(data){
    return request5('query-user-shop-assistant-work-recode-list',data,'post',false)
  },

}
