<template>
<div>
    <div  style="height:82vh;width:100%;background-color: #ffffff;padding:30px; overflow-y: auto;">
                <div style="display: flex; margin-bottom:40px;">
                    <el-button size="mini" style="margin-left:1px;" icon="el-icon-refresh-left" @click="refresh()" :loading="loading">刷新</el-button>
                    <el-button size="mini" style="margin-left:15px;" :loading="loading" type="primary" v-if="!isEdit" @click="cancelEdit()" >取消编辑</el-button>
                    <el-button size="mini" style="margin-left:15px;" :loading="loading" type="primary" v-if="isEdit"  @click="isEdit = false" >编辑资料</el-button>
                    <el-button size="mini" style="margin-left:15px;" :loading="loading" type="primary" v-if="isEdit==false" @click="save()">保存资料</el-button>
                </div>
                <div style="display: flex;">
                    <div>
                        <div style="display: flex; width:200px; margin-bottom:25px;">
                            <div style="height:20px;width:5px;background-color:#096dd9; margin-right:8px;"></div>
                            <div style="font-weight: 700; font-size: 16px;">基本信息</div>
                        </div>
                        <el-form ref="form" :model="form" id="form" label-position="top" label-width="auto" size="mini" >
                            <div style="display: flex;">
                                <el-form-item label="姓名" prop="userName" style="margin-right:35px; width:235px;">
                                    <el-input :disabled="true" v-model="form.userName" ></el-input>
                                </el-form-item>

                                <el-form-item label="工号" prop="employeeId" style="margin-right:35px; width:235px;">
                                    <el-input :disabled="true" v-model="form.employeeId" ></el-input>
                                </el-form-item>

                                <el-form-item label="职级" prop="postNames" style="margin-right:35px; width:235px;">
                                    <el-input :disabled="true" v-model="form.postNames"  ></el-input>
                                </el-form-item>

                                <el-form-item label="角色" prop="roleNames" style="margin-right:35px; width:235px;">
                                  <el-input :disabled="true" v-model="form.roleNames"  ></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                    <div style="position: relative" @click="showViewerList('2')">
                        <img v-if="!imageUrl" :src="form.halfLengthPhoto" style="width:110px;height:130px;margin-left:50px;"  />
                        <img v-if="imageUrl" :src="imageUrl" style="width:110px;height:130px;margin-left:50px;"  />
                        <div class="cover" v-if="!isEdit">
                            <el-upload
                                ref="upload"
                                name="resource"
                                :data="{
                                  resourceType: 'U01',
                                }"

                                :action="urlRoots + uploadResources"
                                :headers="uploadHeaders"
                                :show-file-list="false"
                                :on-preview="()=>{showViewer = true}"
                                :auto-upload="true"
                                :on-remove="(file) => {removeImg(file);}"
                                :on-success="(res) => {imgUploadSuccess(res);}"
                              >

                                <div style="margin-top:50px;">
                                    <img src="../../../assets/images/antOutline-edit.png" style="width:20px;height:20px;" />
				                            <div style="color:#ffffff;" >上传半身照</div>
                                </div>
                            </el-upload>
                            
			            </div>
                    </div>
                </div>
                <el-form ref="formTwo" :model="form" id="formTwo" label-position="top" label-width="auto" size="mini" >
                    <div style="display: flex; ">
                        <el-form-item label="性别" prop="sex" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.sex" ></el-input>
                        </el-form-item>
                        <el-form-item label="年龄" prop="age" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.age" ></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="userInfoPhone" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.userInfoPhone"  ></el-input>
                        </el-form-item>
                        <el-form-item label="状态" prop="jobStateDesc" style=" width:235px;">
                            <el-input :disabled="true" v-model="form.jobStateDesc"  ></el-input>
                        </el-form-item>
                    </div>
                    <div style="display: flex; ">
                        <el-form-item label="身份证号" prop="idNum" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.idNum" ></el-input>
                        </el-form-item>
                        <el-form-item label="身份证有效结束日期" prop="certificateValidity" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.certificateValidity" ></el-input>
                        </el-form-item>
                        <el-form-item label="民族" prop="nationality" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.nationality"  ></el-input>
                        </el-form-item>
                        <el-form-item label="出生日期" prop="birthday" style="margin-right:35px; width:235px;">
                            <!-- <el-input :disabled="isEdit" v-model="form.birthday"  ></el-input> -->
                            <el-date-picker
                              :disabled="true"
                              style="width:235px;"
                              v-model="form.birthday"
                              format="yyyy-MM-dd"
                              type="date"
                              placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div style="display: flex; ">
                        <el-form-item label="户籍类型" prop="registeredResidenceType" style="margin-right:35px; width:235px;">
                            <el-select :disabled="true" v-model="form.registeredResidenceType" style=" width:235px;">
                               <el-option label="农村" value="0"></el-option>
                               <el-option label="城市" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="省市县区" prop="location" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="location" ></el-input>
                        </el-form-item>
                        <el-form-item label="身份证详细地址" prop="address" style="margin-right:35px; width:505px;">
                            <el-input :disabled="true" v-model="form.address"  ></el-input>
                        </el-form-item>
                    </div>
                    <div style="display: flex; ">
                        <el-form-item label="政治面貌" prop="politicsStatus" style="margin-right:35px; width:235px;">
                            <el-input :disabled="isEdit" v-model="form.politicsStatus" ></el-input>
                        </el-form-item>
                        <el-form-item label="婚姻状况" prop="maritalStatus" style="margin-right:35px; width:235px;">
                            <el-select :disabled="isEdit" v-model="form.maritalStatus" style=" width:235px;">
                              <el-option label="未婚" value="未婚"></el-option>
                              <el-option label="已婚" value="已婚"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="现居住地址" prop="liveAddress" style="margin-right:35px; width:505px;">
                            <el-input :disabled="isEdit" v-model="form.liveAddress"  ></el-input>
                        </el-form-item>
                    </div>
                    <div style="display: flex; ">
                        <el-form-item label="学历" prop="highestEducation" style="margin-right:35px; width:235px;">
                            <el-select :disabled="isEdit" v-model="form.highestEducation" style=" width:235px;">
                              <el-option label="小学" value="小学"></el-option>
                              <el-option label="初中" value="初中"></el-option>
                              <el-option label="高中" value="高中"></el-option>
                              <el-option label="大学专科" value="大学专科"></el-option>
                              <el-option label="大学本科" value="大学本科"></el-option>
                              <el-option label="硕士研究生" value="硕士研究生"></el-option>
                              <el-option label="博士研究生" value="博士研究生"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="身高(cm)" prop="height" style="margin-right:35px; width:235px;">
                            <el-input-number v-model="form.height" :disabled="isEdit" :precision="2" :step="0.1" :min="0" :controls="false" style="width:235px;"></el-input-number>
                        </el-form-item>
                        <el-form-item label="体重(kg)" prop="weight" style="margin-right:35px; width:235px;">
                            <el-input-number v-model="form.weight" :disabled="isEdit" :precision="2" :step="0.1" :min="0" :controls="false" style="width:235px;"></el-input-number>
                        </el-form-item>
                        <el-form-item label="穿衣尺码" prop="dressSize" style="margin-right:35px; width:235px;">
                            <el-input :disabled="isEdit" v-model="form.dressSize"  ></el-input>
                        </el-form-item>
                    </div>
                    <div style="display: flex;">
                        <el-form-item label="身份证人像面" prop="identityCardPhoto" style="margin-right:35px; width:235px;">
                          <img style="height:100px;width:200px;" @click="showViewerList('1')" :src="this.form.idCardImgFace" />
                        </el-form-item>
                        <el-form-item label="身份证国徽面" prop="identityCardPhoto" style="margin-right:35px; width:235px;">
                          <img style="height:100px;width:200px;" @click="showViewerList('0')" :src="this.form.idCardImgFlag" />
                        </el-form-item>
                    </div>
                    <!-- 工作信息 -->
                    <div style="display: flex; width:200px; margin:25px 0px;">
                        <div style="height:20px;width:5px;background-color:#096dd9; margin-right:8px;"></div>
                        <div style="font-weight: 700; font-size: 16px;">工作信息</div>
                    </div>
                    <div style="display: flex; flex-wrap: wrap;">
                        <el-form-item label="入职日期" v-if="!rowObj.tryShow" prop="hiredDate" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.hiredDate" ></el-input>
                        </el-form-item>
                        <el-form-item label="应聘渠道" prop="applyChannel" style="margin-right:35px;">
                            <el-select :disabled="true" v-model="form.applyChannel" style=" width:235px;">
                               <el-option label="内部推荐" value="00"></el-option>
                               <el-option label="外部渠道" value="01"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="form.applyChannel=='00'" label="内部推荐人名称" prop="applyChannelDesc" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.applyChannelDesc" ></el-input>
                        </el-form-item>
                        <el-form-item v-if="form.applyChannel=='01'" label="外部渠道名称" prop="applyChannelDesc" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.applyChannelDesc" ></el-input>
                        </el-form-item>
                        <el-form-item label="合同开始时间" prop="startDate" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.startDate" ></el-input>
                        </el-form-item>
                        <el-form-item label="合同结束时间" prop="endDate" style="width:235px;">
                            <el-input :disabled="true" v-model="form.endDate" ></el-input>
                        </el-form-item>
                    </div>
                    <div style="display: flex; flex-wrap: wrap;">
                        <el-form-item label="职级" prop="postNames" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.postNames" ></el-input>
                        </el-form-item>
                        <el-form-item label="离职日期" v-if="!rowObj.tryShow" prop="finalLeaveTime" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.finalLeaveTime"  ></el-input>
                        </el-form-item>
                        <el-form-item label="试用时间" prop="probationDate" style="margin-right:35px; width:235px;">
                            <el-input :disabled="true" v-model="form.probationDate"  ></el-input>
                        </el-form-item>
                        <el-form-item  label="试用结束时间" prop="probationDateEnd" style="margin-right:35px; width:235px;">
                          <el-input :disabled="true" v-model="form.probationDateEnd"  ></el-input>
                        </el-form-item>
                    </div>
                    <!-- 紧急联系人 -->
                    <div style="display: flex; width:200px; margin:25px 0px;">
                        <div style="height:20px;width:5px;background-color:#096dd9; margin-right:8px;"></div>
                        <div style="font-weight: 700; font-size: 16px;">紧急联系人</div>
                    </div>
                    <div style="display: flex; flex-wrap: wrap;">
                        <el-form-item label="姓名" prop="emergencyContact" style="margin-right:35px; width:235px;">
                            <el-input :disabled="isEdit" v-model="form.emergencyContact" ></el-input>
                        </el-form-item>
                        <el-form-item label="关系" prop="emergencyContactRelation" style="margin-right:35px; width:235px;">
                            <el-input :disabled="isEdit" v-model="form.emergencyContactRelation"  ></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="emergencyContactPhone" style="margin-right:35px; width:235px;">
                            <el-input :disabled="isEdit" v-model="form.emergencyContactPhone"  ></el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <el-image-viewer class="image-view" v-if="showViewer" :on-close="closeViewer" :url-list="imageList" />
            </div>
</div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import moment from 'moment'
import newPostUrl from '@/libs/http/modules/oa.js';
import { uploadResource } from '@/libs/http/api.js'
import http from "@/libs/http/index.js";

export default {
    name: '',
    components: {
        ElImageViewer,
    },
    props: ['rowObj'],
    data() {
      return {
          // tryShow:false,
          imageUrl:'',//编辑时的半生照
          showViewer:false,
          isEdit:true,
          imageList:[],
          location:'',//省市县区
          form:{
              userId:'',
              userName:'',//姓名
              sex:'',//性别
              age:'',//年龄
              userInfoPhone:'',//手机号
              employeeId:'',//工号
              idNum:'',//身份证号
              certificateValidity:'',//身份证有效结束日期
              nationality:'',//民族
              birthday:'',//生日 yyyy-MM-dd
              registeredResidenceType:'',//户籍类型 0农村 1城市
              address:'',//身份证详细地
              province:'河南省',//省
              city:'周口市',//市
              area:'西华县',//区
              highestEducation:'',//学历
              height:'',//身高
              weight:'',//体重
              politicsStatus:'',//政治面貌
              maritalStatus:'',//婚姻状况
              liveAddress:'',//现居住地址
              dressSize:'',//衣码

              jobStateDesc:'',//状态
              jobState:'',

              halfLengthPhoto:'',//半身照
              idCardImgFace:'',//身份证反面
              idCardImgFlag:'',//身份证正面

              hiredDate:'',//入职日期
              applyChannel:'',//应聘渠道 00内部 01外部
              applyChannelDesc:'',//内部推荐人或外部渠道名称
              probationDate:'',//试工日期
              probationDateEnd:'',

              finalLeaveTime:'',//离职日期
              startDate:'',//合同开始时间
              endDate:'',//合同结束时间
              postNames:'',//职位名称
              postName:'',//职级名称

              emergencyContact:'',//联系人姓名
              emergencyContactRelation:'',//关系
              emergencyContactPhone:'',//联系人电话
              },
          formCopy:{},
          loading:false,
          urlRoots: "",
          uploadResources: "",
          uploadHeaders: {
            // "X-Token": Db.get("userData.token").value(),
          },
      }
    },
    created () {
      this.uploadResources = '/by-file'
      this.urlRoots = window.urlConfig.uploadRoot;
      console.log(this.urlRoots);
    },
    watch: {   
      rowObj: {  
        handler(newValue, oldValue) {
            this.isEdit = true
            this.imageUrl = ''
            for(let key in this.form){
                this.form[key] = this.rowObj[key]
                if(key=='sex'){
                    if(this.rowObj[key]=='0'){
                        this.form[key]= '女'
                    }else{
                        this.form[key]='男'
                    }
                }
                if(key == 'height' || key == 'weight'){
                  if(this.rowObj[key]){
                    this.form[key] = Number(this.form[key])
                  }
                }

                if( key=='finalLeaveTime' || key=='hiredDate'){
                  if(this.rowObj[key]){
                    this.form[key] = moment(this.rowObj[key]).format('YYYY-MM-DD')
                  }
                }
            } 
            if(this.form.province && this.form.city && this.form.area){
                this.location = this.form.province + this.form.city + this.form.area
            }
            this.formCopy = JSON.parse(JSON.stringify(this.form))
        },  
        deep: true // 深度监听
      } 
    },
    // mounted () {
    //     this.refresh()
    // },
    methods: {
        // 保存
        save(){
            this.isEdit = true
            this.loading = true;
            if(this.form.height){
              this.form.height = this.form.height.toString()
            }else{
              this.form.height = ''
            }
            if(this.form.weight){
              this.form.weight = this.form.weight.toString()
            }else{
              this.form.weight = ''
            }
            this.form.birthday = moment(this.form.birthday).format('YYYY-MM-DD')
            this.form.hiredDate = moment(this.form.hiredDate).format('YYYY-MM-DD')
            newPostUrl.saveStoreManagerOaFrontendUserDetail({
                ...this.form
            }).then(data => {
                this.imageUrl = ''
                this.loading = false;
                this.$message({ type: 'success', message:'保存成功', duration: 2000, showClose: true });
    
            }).catch(err => {
                this.loading = false;
                this.$message({ type: 'error', message: err, duration: 2000, showClose: true });
            })
        },
        // 取消编辑
        cancelEdit(){
            this.loading = true;
            this.isEdit = true
            this.form = this.formCopy
            this.imageUrl = ''
            this.loading = false;
        },
        // 离职图片预览
        closeViewer() {
            this.showViewer = false
        },
        showViewerList(e){
            this.imageList = []
            this.$nextTick(()=>{
                if(e=='1'){
                    this.imageList.push(this.form.idCardImgFace)
                    this.showViewer = true
                }else if(e=='0'){
                    this.imageList.push(this.form.idCardImgFlag)
                    this.showViewer = true
                }else if(e=='2'){
                  if(!this.isEdit)return
                  this.imageList.push(this.form.halfLengthPhoto)
                  this.showViewer = true

                }
            })
        },
        imgUploadSuccess(res) {
          if (res.code == 200) {
            this.$message.success("上传成功");
            const data = JSON.parse(res.data);
            this.imageUrl = data.url
            this.form.halfLengthPhoto = data.url
          } else {
            this.$message({ type: 'error', message: res.msg, duration: 2000, showClose: true })
            // this.form.halfLengthPhoto = ''
            // this.imageUrl = ''
          }
        },
        removeImg() {
          // this.form.halfLengthPhoto = ''
          this.imageUrl = ''
        },
        refresh() {
          this.$emit("refresh");
        },
        
    },
}
</script>

<style scoped>
.image-view {
    z-index: 99999 !important;
}
.el-image-viewer {
    z-index: 99999 !important;
}
.cover {
    top: 0px;
    width: 110px;
    height: 130px;
    text-align: center;
    position:absolute;
    left:0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    margin-left:50px;
}
.cover:hover  {
	opacity: 1;
    transform: scale(1);
    cursor:pointer;
}
</style>