<template>
<div>
    <div style="height:82vh;width:100%;background-color: #ffffff;padding:30px; overflow-y: auto;">
        <el-button size="mini" style="margin-bottom:20px;" icon="el-icon-refresh-left" :loading="loading" @click="fetchList('1')" >刷新</el-button>
        <div v-for="(itemIndex,index) in newArr" :key="index">
            <!-- 入离职 -->
          <div v-if="itemIndex.infoType=='1'">
            <div style="display: flex; justify-content: space-between;">
              <div style="margin-left: 10px; width: 150px; display:flex; ">
                <div style="margin-right:15px;">
                  <div class="transferPostOne"></div>
                  <div class="transferPostshu"></div>
                  <!-- <div class="transferPostTwo"></div> -->
                </div>
                <div>
                  <div v-if="itemIndex.userEntryAndResignInfo.changeType == '1'" style="font-size: 17px; font-weight: 700">入职</div>
                  <div v-if="itemIndex.userEntryAndResignInfo.changeType == '2'" style="font-size: 17px; font-weight: 700">离职</div>
                  <div style="font-size: 13px; color: #aab2c8; margin-bottom: 5px">
                    {{ itemIndex.sortDate || ''}}
                  </div>
                </div>
              </div>
              <div class="drill">
                <div class="marginRightBox" v-if="itemIndex.userEntryAndResignInfo.changeType == '1'" style="font-size:14px;">用户名称：{{ itemIndex.userEntryAndResignInfo.userName || ''}}</div>
                <div class="marginRightBox" v-if="itemIndex.userEntryAndResignInfo.changeType == '1'" style="font-size:14px;">入职店铺：{{ itemIndex.userEntryAndResignInfo.marketName || ''}}</div>
                <div class="marginRightBox" v-if="itemIndex.userEntryAndResignInfo.changeType == '2'" style="font-size:14px;">离职店铺：{{itemIndex.userEntryAndResignInfo.marketName || ''}}</div>
                <div class="marginRightBox" v-if="itemIndex.userEntryAndResignInfo.changeType == '1'" style="font-size:14px;">发生日期：{{ itemIndex.userEntryAndResignInfo.happenDate || ''}}</div>
                <div class="marginRightBox" v-if="itemIndex.userEntryAndResignInfo.changeType == '1'" style="font-size:14px;">职级名称：{{ itemIndex.userEntryAndResignInfo.postName || ''}}</div>
                <div class="marginRightBox" v-if="itemIndex.userEntryAndResignInfo.changeType == '1'" style="font-size:14px;">发生原因：{{ itemIndex.userEntryAndResignInfo.happenReason || ''}}</div>
              </div>
            </div>
          </div>
            <!-- 调岗 -->
          <div v-if="itemIndex.infoType=='2'">
            <div style="display: flex; justify-content: space-between;">
              <div style="margin-left: 10px; width: 150px; display:flex; ">
                <div style="margin-right:15px;">
                  <div class="transferPostOne"></div>
                  <div class="transferPostshu" ></div>
                  <!-- <div class="transferPostTwo"></div> -->
                </div>
                <div>
                  <div style="font-size: 17px; font-weight: 700">调岗</div>
                  <div style="font-size: 13px; color: #aab2c8; margin-bottom: 5px">
                    {{ itemIndex.sortDate || '' }}
                  </div>
                </div>
              </div>
              <div class="drill">
                  <div  style="margin-right:50px;">
                    <div style="text-align:center; font-weight:700;">{{itemIndex.userTrainRecode.sourceMarket || ''}}</div>
                    <div style="text-align:center;">{{itemIndex.userTrainRecode.sourcePost || ''}}</div>
                  </div>
                  <div  style="display:flex;align-items: center; margin-right:50px;">
                    <div style=" width:100px; height:5px; background-color:#d9d9d9;"></div>
                    <div class="promotion" v-if="itemIndex.userTrainRecode.trainType=='降职'" style="background-color: orangered;">{{'降职'}}</div>
                    <div class="promotion" v-if="itemIndex.userTrainRecode.trainType=='升职'" style="background-color: #077cf2;">{{'升职'}}</div>
                    <div class="promotion" v-if="itemIndex.userTrainRecode.trainType=='平调'" style="background-color: limegreen;">{{'平调'}}</div>
                    <div style="width:100px; height:5px; background-color:#d9d9d9;"></div>
                  </div>
                  <div style="margin-right:80px;">
                    <div style="text-align:center;font-weight:700;">{{itemIndex.userTrainRecode.targetMarket || ''}}</div>
                    <div style="text-align:center;">{{itemIndex.userTrainRecode.targetPost || ''}}</div>
                  </div>
                  <div class="marginRightBox" style="font-size:14px; ">是否为新店铺负责人：{{itemIndex.userTrainRecode.isPrincipal == 'Y' ? '是' : '否'}}</div>
                  <div class="marginRightBox" style="font-size:14px; ">生效时间：{{itemIndex.userTrainRecode.effectiveTime || ''}}</div>
              </div>
            </div>
          </div>
           <!-- 出差 -->
          <div v-if="itemIndex.infoType=='3'">
            <div style="display: flex; justify-content: space-between;">
              <div style="margin-left: 10px; width: 150px; display:flex; ">
                <div style="margin-right:15px;">
                  <div class="transferPostOne"></div>
                  <div class="transferPostshu"></div>
                </div>
                <div>
                  <div style="font-size: 17px; font-weight: 700">出差</div>
                  <div style="font-size: 13px; color: #aab2c8; margin-bottom: 5px">
                    {{ itemIndex.sortDate || ''}}
                  </div>
                </div>
              </div>
              <div class="drill" >
                <div class="marginRightBox" style="font-size:14px;" >原店铺：{{itemIndex.userTripRecode.sourceMarket}}</div>
                <div class="marginRightBox" style="font-size:14px;" >目标店铺：{{itemIndex.userTripRecode.targetMarket}}</div>
                <div class="marginRightBox" style="font-size:14px;" >开始时间：{{itemIndex.userTripRecode.startTime}}</div>
                <div class="marginRightBox" style="font-size:14px;" >结束时间：{{itemIndex.userTripRecode.endTime}}</div>
              </div>
            </div>
          </div>
            <!-- 培训 -->
          <div v-if="itemIndex.infoType=='4'">
            <div style="display: flex; justify-content: space-between;">
              <div style="margin-left: 10px; width: 150px; display:flex; ">
                <div style="margin-right:15px;">
                  <div class="transferPostOne"></div>
                  <div class="transferPostshu" ></div>
                </div>
                <div>
                  <div style="font-size: 17px; font-weight: 700">培训</div>
                    <div style="font-size: 13px; color: #aab2c8; margin-bottom: 5px" >
                      {{ itemIndex.sortDate || '' }}
                    </div>
                </div>
              </div>
              <div class="drill" >
                <div class="marginRightBox" style="font-size:14px;">原店铺：{{itemIndex.userBusinessTrainingRecode.sourceMarket}}</div>
                <div class="marginRightBox" style="font-size:14px;">培训店铺：{{itemIndex.userBusinessTrainingRecode.targetMarket}}</div>
                <div class="marginRightBox" style="font-size:14px;">开始时间：{{itemIndex.userBusinessTrainingRecode.startTime}}</div>
                <div class="marginRightBox" style="font-size:14px;">结束时间：{{itemIndex.userBusinessTrainingRecode.endTime}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="newArr.length > 0" class="transferPostTwo"></div>
        <div v-if="userShow" style="width:100%;height:500px; display:flex; justify-content:center; align-items: center ">
          <img src="@/assets/images/empty.png" alt="暂无数据"  />
        </div>
        <div v-if="userShow" style="display:flex; justify-content:center; align-items: center;width:100%;height:20px;">暂无数据</div>
    </div>
</div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import newPostUrl from '@/libs/http/modules/oa.js'
export default {
  name: 'flowTrack',
  components: {
      ElImageViewer,
  },
  props: ['rowObj'],
  data() {
    return {
      newArr:[],
      userShow:null,
      loading:false,
    }
  },

  mounted() {
    this.fetchList()
  },

  watch:{
    'rowObj.userId':{
      handler: function() {
        this.fetchList()
      },
    }
  },

  methods: {
      fetchList(num){
        if(!this.rowObj.userId){
          this.$message.error("请选择员工")
          return
        }
        let reqData = {
          userId:this.rowObj.userId
        }
        this.loading = true
        newPostUrl.queryUserShopAssistantWorkRecodeList(reqData).then((data)=>{
          this.newArr=[]
          if(data.length > 0){
            this.newArr = data
            this.userShow = null
          }else{
            this.userShow = '1'
          }
          if(num){
            this.$message({ type: 'success', message: '已刷新', duration: 2000, showClose: true })
          }
          this.loading = false
        }).catch((err)=>{
            this.$message.error(err);
            this.loading = false
            console.log(err)
        })
      },

  }
}
</script>

<style scoped>
.image-view {
    z-index: 99999 !important;
}
.el-image-viewer {
    z-index: 99999 !important;
}
.transferPostOne{
  border:1px solid #1890ff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
.transferPostshu{
  background-color: #1890ff;
  width: 3px;
  height: 80px;
  margin-left: 6px;
}
.transferPostTwo{
  border:1px solid #1890ff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-left:10px;
}
.drill{
  width:100%;
  background-color:#f9fafc;
  padding:0 20px;
  margin-top: 10px;
  height:80px;
  //line-height: 40px;
  display:flex;
  //margin-right:40px;
  align-items: center;
  //justify-content: center;
  //justify-content: space-between;
}
.marginRightBox{
  margin-right:50px;
  width:180px;
}
.promotion{
  border:1px solid #0078d4;
  border-radius:10%;
  height:25px;
  width:60px;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.none{
  color:#f2f2f2;
  text-align: center;
  font-size: 25px;
}
</style>