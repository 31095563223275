// 统一的请求发送
import axios from 'axios'
import qs from 'qs'

// import sysConfig from '@/config/index'
import tool from './tool'

import Cache from '../libs/common/cache'
import Log from '../libs/common/log'
import StringTool from '../libs/common/stringTool'
import AES from '../libs/common/AES'
import packageJson from '../../package.json'
import vue from '../main'
import newPostUrl from '@/libs/http/modules/oa.js'

const urlConfig = window.urlConfig;
const newUrlRoot = urlConfig.newUrlRoot;
const appId = '00000020'
const channel = '20'
const logFun = (link, reqData, resData, key) => {
  Log.normal('url: ' + link)
  if (resData && +resData.code === 200) {
    let options = key && reqData['data'] ? AES.ECB_decrypt(reqData['data'], key) : JSON.stringify(reqData)
    // Log.success('接口返回数据：' + options)
    if (key) {
      const data = AES.ECB_decrypt(resData['data'], key)
      Log.normal('接口code:200返回结果：' + data)
    } else {
      Log.normal('接口code:200返回结果：' + resData)
    }
  } else {
    let options = key && reqData['data'] ? AES.ECB_decrypt(reqData['data'], key) : JSON.stringify(reqData)
    Log.error('接口返回数据：' + options)
    if (resData && resData['msg']) {
      Log.normal(JSON.stringify(resData))
    }
  }
}
const SECRET_KEY = 'BM4GSYT8Jne2GR6A' //秘钥

const errorCodeMap = {
  400: '必要参数未传(s-appId、s-ctime)',
  401: '需要验证签名时，签名为空或签名值不正确。',
  403: 's-ctime时间与服务器时间间隔超过配置的最大时间差',
  406: '登录状态已失效，请重新登录',
  500: '服务器内部错误',
  501: 's-appId未找到对应的接入应用。',
  504: '网关未正常配置接入应用。'
}
// 定义一个重新登录弹出窗的变量
// const loginBack = false
// 创建 axios 实例
const service = axios.create({
  baseURL: '/api', // api base_url
  // timeout: 60000, // 后端有超时校验前端不需要配置
  withCredentials: true
})

// HTTP request 拦截器
service.interceptors.request.use(
  config => {
    const token = tool.data.get('TOKEN')
    if (token) {
      config.headers['token'] = '' + token
    }
    if (config.method === 'get') {
      config.params = config.params || {}
      config.params._ = new Date().getTime()
    }
    // Log.success('接口请求参数：' +  JSON.stringify(config.data))
    Object.assign(config.headers, {})

    // const reqData = {
    // appId: appId,
    // channel: channel,
    // serial: StringTool.getNowFormatDate() + StringTool.getNowFormatTime() + StringTool.getRandomNum(1000, 9999)
    // }
    // if (config.data) {
    // 	reqData['data'] = AES.ECB_encrypt(JSON.stringify(config.data), SECRET_KEY)
    // }
    const SCTime = Date.now()
    let lastTwoDigits = SCTime.toString().slice(-2)
    let version = packageJson.version
    const header = {
      // 'Content-Type': 'application/json',
      's-appId': appId,
      's-ctime': SCTime,
      's-sign': StringTool.md5(appId + ',' + SCTime + ',' + lastTwoDigits),
      's-ver': version //客户端版本
    }
    // console.log(appId, SCTime, StringTool.md5(appId + ',' + SCTime + ',' + lastTwoDigits), '--------------------参数')
    // config.data = reqData

    //  这一步的参数在登录接口获取
    if (token) {
      header['s-token'] = tool.data.get('TOKEN')
    }

    Object.assign(config.headers, header)
    return config
  },
  error => {
    // const SCTime = Date.now()
    // let lastTwoDigits = SCTime.toString().slice(-2)
    // console.log(appId, SCTime, StringTool.md5(appId + ',' + SCTime + ',' + lastTwoDigits), '--------------------参数')
    return Promise.reject(error)
  }
)

// 保持重新登录Modal的唯一性
const errorlog = () => {
  vue.$message({ type: 'error', message: '登录失效，请刷新当前页面重试即可', duration: 3000, showClose: true })
  // loginBack = false
  tool.data.remove('TOKEN')
  tool.data.remove('USER_INFO')
  tool.data.remove('MENU')
  tool.data.remove('PERMISSIONS')

  //新接口调用需要的新token
  const newRequest = async () => {
    try {
      await newPostUrl.newLogin()
    } catch (error) {
      vue.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
    }
  }
  newRequest()
}

// HTTP response 拦截器
service.interceptors.response.use(
  response => {
    const link = response.config.url
    tool.data.set('TOKEN', response.headers['s-token'] ? response.headers['s-token'] : '')
    if (response.config.responseType === 'blob') {
      // 配置了blob，不处理直接返回文件流
      if (response.config.responseType === 'blob') {
        if (response.status === 200) {
          return response
        } else {
          return
        }
      }
      return Promise.resolve(data.data)
    }

    if (validResponse(response)) {
      if (response['data']['data'] && response.config.responseType !== 'blob') {
        const resData = AES.ECB_decrypt(response['data']['data'], SECRET_KEY)
        logFun(link, resData, response['data'], SECRET_KEY)
        return Promise.resolve(JSON.parse(resData))
      } else {
        return Promise.resolve({})
      }
    } else {
      vue.$message({ type: 'error', message: response['data']['msg'], duration: 2000, showClose: true })
      return Promise.reject({ msg: response['data']['msg'] })
    }
  },
  error => {
    if (error) {
      if (error.response && error.response.status && error.response.status == 406) {
        // if (!loginBack) {
        //   errorlog()
        // }
        errorlog()
        return Promise.resolve(error)
      } else {
        let statusType = null
        if (error.response && error.response.status) {
          statusType = errorCodeMap[error.response.status]
        }
        vue.$message({ type: 'error', message: statusType ? statusType : '其他异常', duration: 2000, showClose: true })
        return Promise.reject(statusType ? { msg: statusType } : '其他异常')
      }
    }
  }
)


function validResponse(response) {
  const SUCCESS = '200'
  return response && response['data'] && response['data']['code'] === SUCCESS
}

// const str = 'https://pre.soko.core.babiboy.cn/su7' //新系统公测环境
// const str = 'http://192.168.2.173:27000' //澳恒本地环境
// const str = 'http://192.168.2.188:27100' //司徒本地环境
const str = newUrlRoot //正式||公测环境

// 适配器, 用于适配不同的请求方式
export const baseRequest = (url, value = {}, method = 'post', options = {}) => {
  Log.success('接口请求参数：' + JSON.stringify(value))
  url = str + url
  if (method === 'post') {
    return service.post(url, value, options)
  } else if (method === 'get') {
    return service.get(url, { params: value, ...options })
  } else if (method === 'formdata') {
    // form-data表单提交的方式
    return service.post(url, qs.stringify(value), {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      ...options
    })
  } else {
    // 其他请求方式，例如：put、delete
    return service({
      method: method,
      url: url,
      data: value,
      ...options
    })
  }
}

// 模块内的请求, 会自动加上模块的前缀
export const moduleRequest =
  moduleUrl =>
  (url, ...arg) => {
    return baseRequest(moduleUrl + url, ...arg)
  }

export default service
