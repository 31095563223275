<template>
    <section>
      <stockTopMenu curPosition="店员管理" windowId="employeeManage" module="employManage">
        <div
          slot="descript"
          class="row align-center space-between"
          style="margin-left: 100px"
        ></div>
      </stockTopMenu>
      <!-- top -->
      <div style="width:100%; height:60px;padding:10px 20px;">
        <div style="width:100%; height:50px; background-color: #ffffff; padding-top:5px; display: flex;">
            <div  style="display:flex;margin-top:6px;">
                <div>
                    <span style="margin-left:10px;">姓名：</span><el-input size="mini" clearable v-model="userName" style="width:180px;"></el-input>
                    <span style="margin-left:10px;">状态：</span>
                    <el-select size="mini" multiple v-model="RecruitmentChannels" collapse-tags clearable style="width:180px;">
                       <el-option label="正式" value="20"></el-option>
                       <el-option label="试工中" value="0"></el-option>
                       <el-option label="入职审核中" value="10"></el-option>
                       <el-option label="离职审核中" value="30"></el-option>
                       <el-option label="人事审核离职中" value="40"></el-option>
                       <el-option label="待离职" value="50"></el-option>
                       <el-option label="已离职" value="-20"></el-option>
                      <el-option label="试工自离" value="-12"></el-option>
                       <el-option label="试工未通过" value="-10"></el-option>
                    </el-select>
                    <el-button size="mini" style="margin-left:15px;" :loading="loading" type="primary" @click="fetchTwo()">查询</el-button>
                </div>
                <el-button size="mini" style="background-color:#c6e7ff; margin-left:20px; height:30px;" @click="dialogVisible=true">应聘登记</el-button>
                <el-button size="mini" style="background-color:#c6e7ff; height:30px;" @click="getQRCode()">应聘二维码</el-button>
            </div>
        </div>
      </div>
      <!-- body -->
      <div style="display: flex;">
        <div style="width:25%;margin:10px 10px 10px 20px;">
            <grid-manager :option="gridOption"></grid-manager>
        </div>
        <div style="margin:10px 20px 10px 0px; background-color: #ffffff; height: 40px; width:75%;">
            <div style="display: flex;border-bottom:1px solid #e6e6e6;">
                <div :class="menum=='0'?'clickTopActive':''" class="clickTop" @click="menubarClick('0')" style="cursor:pointer;">员工资料</div>
                <div :class="menum=='1'?'clickTopActive':''" class="clickTop" @click="menubarClick('1')" style="cursor:pointer;">流动轨迹</div>
            </div>
            <!-- 员工资料 -->
            <StaffData v-if="menum=='0' && !dataState" :rowObj="rowObj"  ref="staffData" @refresh="detail(rowHanClick,1)"></StaffData>
            <!-- 流动轨迹 -->
            <FlowTrack v-if="menum=='1'  && !dataState" ref="flowTrackKey" :rowObj="rowObj"></FlowTrack>
            <div v-if="dataState" class="dataStateStyle">请先选择店员</div>
        </div>
      </div>

      <ApplicationRecord v-if="dialogVisible" @close="close()"></ApplicationRecord>
      <!-- 二维码 -->
      <el-dialog
        width="15%"
        title="二维码"
        :visible.sync="QRCode"
        :close-on-click-modal="false"
        center
        >
        <div >
          <div ref="qrcodeImage"  id="qrCode"  style="width:200px;height:200px;margin-left:20px;"></div>
          <div style="display:flex;margin-left:80px;margin-bottom:10px;">
            <el-button  type="text" icon="el-icon-download" @click="downloadQRCode()" >下载二维码</el-button>
          </div>
        </div>
      </el-dialog>
      <!-- 确认离职 -->
      <el-dialog
        width="20%"
        title="确认离职"
        :visible.sync="showDimission"
        :close-on-click-modal="false"
        :before-close="handleClose"
        center
        >
            <el-form :model="dimissionForm" :rules="dimissionRules" ref="dimissionForm" label-position="top" label-width="100px" >
                <el-form-item label="预计离职日期" prop="leavePlanTime" >
                    <el-date-picker
                      v-model="dimissionForm.leavePlanTime"
                      type="date"
                      :disabled="true"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际离职日期" prop="finalLeaveTime">
                  <el-date-picker
                      v-model="dimissionForm.finalLeaveTime"
                      type="date"
                      :picker-options="pickerOptions"

                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
                <el-alert
                  title="确认离职后员工的账号将关闭，请确保员工已完成工作交接！"
                  type="warning"
                  style="margin-bottom:20px;"
                  show-icon>
                </el-alert>
                <div style="display:flex;margin-left:180px;margin-bottom:10px;">
                    <el-button size="mini" style="margin-left:10px;" :loading="loading" @click="showDimission = false" >取消</el-button>
                    <el-button size="mini" style="margin-left:10px;" :loading="loading"  type="primary" @click="dimissionFormSubmit()">确定</el-button>
                </div>
            </el-form>
      </el-dialog>
      <!-- 离职审核 -->
      <el-dialog
        width="30%"
        title="离职审核"
        top="100px"
        :visible.sync="showDimissionCheck"
        :close-on-click-modal="false"
        :before-close="handleClose"
        center
        >
            <el-form :model="DimissionCheckForm" :rules="DimissionCheckRules" ref="DimissionCheckForm" label-position="top" label-width="100px" >
                <el-form-item label="预计离职日期" prop="leavePlanTime" style="margin-bottom:0px;">
                    <el-date-picker
                      v-model="DimissionCheckForm.leavePlanTime"
                      :disabled="true"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="离职原因" prop="leaveReasonId"  style="margin-bottom:0px;">
                    <el-radio-group v-model="DimissionCheckForm.leaveReasonId" >
                      <el-radio label="1">个人原因</el-radio>
                      <el-radio label="2">业绩不达标</el-radio>
                      <el-radio label="3">自离</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="具体原因" prop="userReason"  style="margin-bottom:0px;">
                    <el-input type="textarea" v-model="DimissionCheckForm.userReason" rows="4" :disabled="true" ></el-input>
                </el-form-item>
               <el-form-item label="离职签名" prop="leaveAttachFile" style="margin-bottom:0px;">
                 <el-image

                     style="width: 200px; height: 100px"
                     :src="DimissionCheckForm.leaveAttachFile"
                     :preview-src-list="[DimissionCheckForm.leaveAttachFile]">查看图片
                 </el-image>
<!--                 <el-button size="mini"  @click="showViewerList()">查看签名</el-button>-->
               </el-form-item>
                <el-form-item label="店长意见" prop="performanceImportance">
                    <div style="display:flex; margin-bottom:20px;">
                        <div style="width:100px;"><span  style="color:red;">*</span>业绩重视度</div>
                        <el-select  v-model="DimissionCheckForm.performanceImportance" style="width: 300px">
                           <el-option label="重视" value="1"></el-option>
                           <el-option label="比较重视" value="2"></el-option>
                           <el-option label="一般重视" value="3"></el-option>
                           <el-option label="不重视" value="4"></el-option>
                        </el-select>
                    </div>
                    <div style="display:flex; margin-bottom:20px">
                        <div style="width:100px;"><span  style="color:red;">*</span>工作配合度</div>
                        <el-select  v-model="DimissionCheckForm.cooperationLevel" style="width: 300px" >
                           <el-option label="非常配合" value="1"></el-option>
                           <el-option label="比较配合" value="2"></el-option>
                           <el-option label="一般配合" value="3"></el-option>
                           <el-option label="不配合" value="4"></el-option>
                        </el-select>
                    </div>
                    <div style="display:flex; ">
                        <div style="width:100px;"><span  style="color:red;">*</span>优胜劣汰</div>
                        <el-select  v-model="DimissionCheckForm.isEliminate" style="width: 300px" >
                           <el-option label="否" value="0"></el-option>
                           <el-option label="是" value="1"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="店长评语" prop="inputText">
                    <el-input type="textarea" v-model="DimissionCheckForm.storeManagerComment" rows="4" maxlength="50" show-word-limit ></el-input>
                </el-form-item>
                <div style="display:flex;margin-left:180px;margin-bottom:10px;">
                    <el-button size="mini" style="margin-left:10px;" :loading="loading" @click="showDimissionCheck = false" >取消</el-button>
                    <el-button size="mini" style="margin-left:10px;" :loading="loading" type="primary" @click="submitDimission()" >审批通过</el-button>
                </div>
            </el-form>
      </el-dialog>
      <!-- 申请转正 -->
      <el-dialog
        width="40%"
        title="确认试工结果"
        :visible.sync="applyForFullMember"
        :close-on-click-modal="false"
        :before-close="handleClose"
        >
        试工结果：转正入职
            <el-form :model="applyForFullMemberForm" :rules="applyForFullMemberRules" ref="applyForFullMemberForm" label-position="top" label-width="100px" >
               <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item label="正式入职日期" prop="hiredDate" >
                            <el-date-picker
                              style="width:200px;"
                              size="mini"
                              v-model="applyForFullMemberForm.hiredDate"
                              type="date"
                              placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="入职照片(与店长的合照)" prop="entryAttachFile" >
                            <div style="width: 150px;height:150px;overflow: hidden;">
                                <el-upload
                                ref="upload"
                                accept=".jpg,.png,.jpeg"
                                list-type="picture-card" 
                                name="resource" 
                                :data="{
                                  resourceType: 'U01',
                                }" 
                                :limit="1" 
                                :action="urlRoots + uploadResources" 
                                :headers="uploadHeaders" 
                                :auto-upload="true"
                                :on-preview="handlePictureCardPreview" 
                                :file-list="fileListImg"
                                :on-remove="
                                  (file) => {
                                    removeImg(file);
                                  }
                                " :on-exceed="
                                  (file) => {
                                    limitNumb(file);
                                  }
                                " :on-success="
                                  (res) => {
                                    imgUploadSuccess(res);
                                  }
                                ">
                              <i class="el-icon-plus"></i>
                            </el-upload>
                            </div>
                            
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="户籍类型" prop="registeredResidenceType">
                            <el-select size="mini" v-model="applyForFullMemberForm.registeredResidenceType" style="width:200px;" >
                               <el-option label="农村" value="0"></el-option>
                               <el-option label="城市" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="身高(cm)" prop="height">
                          <el-input-number v-model="applyForFullMemberForm.height" size="mini" :precision="2" :step="0.1" :min="0" :controls="false" style="width:200px;"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="体重(kg)" prop="weight">
                          <el-input-number v-model="applyForFullMemberForm.weight" size="mini" :precision="2" :step="0.1" :min="0" :controls="false" style="width:200px;"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="穿衣尺码" prop="dressSize">
                            <el-input size="mini" v-model="applyForFullMemberForm.dressSize" style="width:200px;" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="政治面貌" prop="politicsStatus">
                            <el-input size="mini"  v-model="applyForFullMemberForm.politicsStatus"  style="width:200px;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="现居住地址" prop="liveAddress">
                            <el-input size="mini" v-model="applyForFullMemberForm.liveAddress"  ></el-input>
                        </el-form-item>
                    </el-col> 
                    <el-col :span="24">
                        <div style="display:flex;margin-left:400px;margin-bottom:10px;justify-content:space-between;">
                            <div></div>
                            <div style="display:flex;">
                                <el-button size="mini" style="margin-left:10px;" :loading="loading" @click="determineClose()" >取消</el-button>
                                <el-button size="mini" style="margin-left:10px;" :loading="loading" type="primary" @click="submitApplyForFullMember()" >确定</el-button>
                            </div>
                        </div>
                    </el-col>
               </el-row>
            </el-form>
      </el-dialog>
      <!-- 自离 -->
      <el-dialog
        width="25%"
        title="确认试工结果"
        :visible.sync="ownDimission"
        :close-on-click-modal="false"
        :before-close="handleClose"
        >
        <div style="margin-bottom:20px;">试工结果：自离</div>
            <el-form :model="ownDimissionForm" :rules="ownDimissionRules" ref="ownDimissionForm" label-position="top" label-width="100px" >
                <el-form-item label="试工结束日期" prop="probationDateEnd" >
                    <el-date-picker
                        style="width:200px;"
                        size="mini"
                        v-model="ownDimissionForm.probationDateEnd"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="具体说明" prop="cause" >
                    <el-input type="textarea" v-model="ownDimissionForm.cause" rows="8" maxlength="200" show-word-limit></el-input>
                </el-form-item>
                <div style="display:flex;margin-left:280px;margin-bottom:10px;">
                    <el-button size="mini" style="margin-left:10px;" @click="ownDimission = false;ownDimissionForm.cause = '';ownDimissionForm.probationDateEnd = ''" >取消</el-button>
                    <el-button size="mini" style="margin-left:10px;"  type="primary" :loading="loading"  @click="submitOwnDimission('-12')">确定</el-button>
                </div>
            </el-form>
      </el-dialog>
      <!-- 劝退 -->
      <el-dialog
        width="25%"
        title="确认试工结果"
        :visible.sync="persuadeSomebodyToQuit"
        :close-on-click-modal="false"
        :before-close="handleClose"
        >
        <div style="margin-bottom:20px;">试工结果：劝退</div>
            <el-form :model="persuadeSomebodyToQuitForm" :rules="persuadeSomebodyToQuitRules" ref="persuadeSomebodyToQuitForm" label-position="top" label-width="100px" >
                <el-form-item label="试工结束日期" prop="probationDateEnd" >
                  <el-date-picker
                      style="width:200px;"
                      size="mini"
                      v-model="persuadeSomebodyToQuitForm.probationDateEnd"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="具体说明" prop="cause" >
                    <el-input type="textarea" v-model="persuadeSomebodyToQuitForm.cause" rows="8" maxlength="200" show-word-limit></el-input>
                </el-form-item>
                <div style="display:flex;margin-left:280px;margin-bottom:10px;">
                    <el-button size="mini" style="margin-left:10px;" @click="persuadeSomebodyToQuit=false;persuadeSomebodyToQuitForm.cause='';persuadeSomebodyToQuitForm.probationDateEnd=''" >取消</el-button>
                    <el-button size="mini" style="margin-left:10px;"  type="primary" :loading="loading"  @click="submitOwnDimission('-10')" >确定</el-button>
                </div>
            </el-form>
      </el-dialog>
      <!-- 不同意 -->
      <el-dialog
        width="25%"
        title="不同意离职"
        :visible.sync="rejectDissmion"
        :close-on-click-modal="false"
        :before-close="handleClose"
        >
        不同意离职会撤销申请，确定不同意离职？
                <div style="display:flex;margin-left:280px;margin-bottom:10px;">
                    <el-button size="mini" style="margin-left:10px;" @click="rejectDissmion = false; rows=null" >取消</el-button>
                    <el-button size="mini" style="margin-left:10px;"  type="primary" :loading="loading" @click="reject()" >确定</el-button>
                </div>
      </el-dialog>
      <el-image-viewer style="z-index: 9999;" class="image-view" v-if="prevImage" :on-close="closeViewer" :url-list="imageList" />
      
    </section>
  </template>
  <script>
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  import stockTopMenu from "@/components/stockTopMenu.vue";
  import ApplicationRecord from './components/ApplicationRecord.vue'
  import FlowTrack from "./components/flowTrack.vue"
  import StaffData from "./components/staffData.vue"
  import moment from 'moment'
  import newPostUrl from '@/libs/http/modules/oa.js'
  import tool from '@/utils/tool.js'
  import QRCode from 'qrcodejs2'
  import { uploadResource } from '@/libs/http/api.js'
  import http from "@/libs/http/index.js";

  export default {
    name: "employeeManage",
    components: {
      stockTopMenu,
      ElImageViewer,
      ApplicationRecord,
      FlowTrack,
      StaffData,
    },
    data() {
      return {
        dataState:true,
        rowHanClick:'',
        rowObj:'',
        loading:false,
        dialogImageUrl: "",
        prevImage: false,
        urlRoots: "",
        uploadResources: "",
        uploadHeaders: {
        // "X-Token": Db.get("userData.token").value(),
        },
        fileListImg: [], //编辑时展示图片列表

        QRCode:false,
        dialogVisible:false,
        userName:'',
        RecruitmentChannels:[],
        menum:'0',
        
        gridOption: {
                gridManagerName: 'employeeManageGrid',
                firstLoading: false,
                isCombSorting: false,
                supportCheckbox: false,
                supportAutoOrder: false,
                useTrFocus: true,
                lineHeight: '25px',
                pageSize: 100,
                sizeData: [100, 200, 500, 1000],
                columnData: [
                    { key: 'employeeId', text: '工号',width:'100px', },
                    { key: 'userName', text: '姓名',width:'100px',},
                    { key: 'jobStateDesc', text: '状态',width:'100px',},
                    {
                        key: 'operate',
                        text: '操作',
                        width:'170px',
                        template: () => {
                            return `
                                <div v-if="row.jobState=='0'">
                                    <el-button type="text" style="padding: 0;font-size: 12px;" @click="confirmApplyForFullMember(row)">申请转正</el-button>
                                    <el-button type="text" style="padding: 0;font-size: 12px;" @click="ifPersuadeSomebodyToQuit(row)">劝退</el-button>
                                    <el-button type="text" style="padding: 0;font-size: 12px;" @click="ifOwnDimission(row)">自离</el-button>
                                </div>
                                <div v-if="row.jobState=='50'">
                                   <el-button type="text" style="padding: 0;font-size: 12px;" @click="confirmDimission(row)">确认离职</el-button>
                                    <el-button type="text" style="padding: 0;font-size: 12px;" @click="cancelDimission(row)">撤销离职</el-button>
                                </div>
                                <div v-if="row.jobState=='30'" >
                                    <el-button type="text" style="padding: 0;font-size: 12px;" @click="confirmDimissionCheck(row)">离职审核</el-button>
                                    <el-button type="text" style="padding: 0;font-size: 12px;" @click="disagree(row)">不同意</el-button>
                                </div>
                                
                            `
                        },
                    },
                ],
                rowClick:this.rowClick,
                ajaxData: this.fetch,
                supportAjaxPage: true,

                totalsKey: 'totalItem',
                height: '86vh',
                ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                  <!--每页显示条数-->
                  <div class="change-size">
                      {{ vm.pageSizeOptionTpl }}
                  </div>
                  <!--选中信息-->
                  <div class="toolbar-info checked-info"></div>
                  <!--分页描述-->
                  <div class="toolbar-info page-info"></div>
                  <!--分页切换-->
                  <div class="pagination">
                      <!--上一页、首页区-->
                      <ul pagination-before>
                          <li class="previous-page">
                              {{ vm.previousPageText }}
                          </li>
                      </ul>
                      <!--下一页、尾页区-->
                      <ul pagination-after>
                          <li class="next-page">
                              {{ vm.nextPageText }}
                          </li>
                      </ul>
                  </div>
              </div>`
        },

        // 确认离职
        showDimission:false,
        dimissionForm:{
            leavePlanTime:'',//预计离职日期
            finalLeaveTime:'',//实际离职日期
        },
        pickerOptions: {
            disabledDate(time) {
                return time.getTime() > Date.now(); //只能选择今天及今天之前的日期
            },
        },
        dimissionRules:{
            finalLeaveTime: [
               { required: true, message: '请选择', trigger: 'blur' },
             ],
        },
        // 离职审核
        showDimissionCheck:false,
        DimissionCheckForm:{
            leavePlanTime:'',//预计离职日期
            userReason:'',//具体原因
            leaveReasonId:'',
            performanceImportance:'',
            cooperationLevel:'',
            isEliminate:'',
            storeManagerComment:'',
            leaveAttachFile:'',//离职签名
        },
        DimissionCheckRules:{
            finalLeaveTime: [
               { required: true, message: '请选择', trigger: 'blur' },
             ],
        },
        // 申请转正
        applyForFullMember:false,
        applyForFullMemberForm:{
            hiredDate:'',
            entryAttachFile:[],
            registeredResidenceType:'',
            weight:'',
            height:'',
            dressSize:'',
            politicsStatus:'',
            liveAddress:'',
        },
        applyForFullMemberRules:{
            hiredDate: [
               { required: true, message: '请选择', trigger: 'blur' },
             ],
            entryAttachFile: [
               { required: true, message: '请上传', trigger: 'blur' },
             ],
             registeredResidenceType:[
                { required: true, message: '请选择', trigger: 'blur' },
             ],
             height:[
                { required: true, message: '请输入', trigger: 'blur' },
             ],
             weight:[
                { required: true, message: '请输入', trigger: 'blur' },
             ],
             liveAddress:[
                { required: true, message: '请输入', trigger: 'blur' },
             ],
        },
        // 自离
        ownDimission:false,
        ownDimissionForm:{
          cause:'',
          probationDateEnd:'',
        },
        ownDimissionRules:{
          cause:[
            { required: true, message: '请输入', trigger: 'blur' },
          ],
          probationDateEnd:[
            { required: true, message: '请输入', trigger: 'change' }
          ]

        },
        // 劝退
        persuadeSomebodyToQuit:false,
        persuadeSomebodyToQuitForm:{
            cause:'',
          probationDateEnd:'',
        },
        persuadeSomebodyToQuitRules:{
            cause:[
                { required: true, message: '请输入', trigger: 'blur' },
             ],
          probationDateEnd:[
            { required: true, message: '请输入', trigger: 'change' }
          ]
        },
        // 不同意
        rejectDissmion:false,
        rows:null,
        showViewer:false,
        imageList:[],

      };
    },
    created () {
        this.urlRoots = 'https://183.6.86.105:28288';
        this.uploadResources = '/galaxy/minio/babiboy/by-file'
        // this.urlRoots = http.urlRoot;
        // console.log(this.urlRoots);
        // this.uploadResources = uploadResource;

        let tokenTime = tool.data.get('TOKEN_TIME')
        if(tool.data.get('TOKEN') && !this.hasExceededThirtyMinutes(tokenTime)){
            console.log('y有token了，来刷新')
            setTimeout(() => {
              GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }, 60) //延迟获取表格数据(页面初始化时调用会提示表格没初始化)
        }else{
             //新接口调用需要的新token
            const newRequest = async () => {
              try {
                const result = await newPostUrl.newLogin()
                if (result) {
                  setTimeout(() => {
                    GridManager.refreshGrid(this.gridOption.gridManagerName, true)
                  }, 60) //延迟获取表格数据(页面初始化时调用会提示表格没初始化)
                }
              } catch (error) {
                this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
              }
            }
            newRequest()
        }
    },
  
    methods: {
        // 生成二维码
        showQRCode(res) {
          this.loading = false;
          //先清除已有二维码
          this.$refs.qrcodeImage.innerHTML = ''
          let code = new QRCode(this.$refs.qrcodeImage, {
            text: res,
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff'
          })
        },
        open(){
          this.QRCode = true;
          this.loading = true;
        },
        async getQRCode(){
           await this.open()
           newPostUrl.getOaUserApplyJobQrCode({}).then(res=>{
              // setTimeout(()=>{
                this.showQRCode(res)
              // },300)
           }).catch(err=>{
              this.loading = false;
              this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
           })
        },
        downloadQRCode() {
          // 找到二维码所在的canvas元素
          const canvas = this.$refs.qrcodeImage.querySelector('canvas');
          if (canvas) {
            // 创建a标签用于触发下载
            const link = document.createElement('a');
            link.href = canvas.toDataURL();  // 将canvas内容转换为dataURL
            link.download = 'qrcode.png';  // 设置下载文件名
            link.style.display = 'none';

            // 添加到DOM并触发点击事件
            document.body.appendChild(link);
            link.click();

            // 清理
            document.body.removeChild(link);
          } else {
            console.error('未能找到二维码的canvas元素');
          }
        },
        // 判断是否超时30分钟
		    hasExceededThirtyMinutes(tokenTime) {
		    	if(!tokenTime){
		    		return true
		    	}
		        const THIRTY_MINUTES_MS = 30 * 60 * 1000; // 30分钟的毫秒数
		        const currentTimestamp = Date.now(); // 当前时间戳
		        const timeDifference = currentTimestamp - tokenTime; // 计算时间差
		        return timeDifference >= THIRTY_MINUTES_MS; // 判断是否超过30分钟
		    },
        close(){
            this.dialogVisible = false
        },
        fetchTwo(){
          GridManager.cleanData(this.gridOption.gridManagerName)
          GridManager.refreshGrid(this.gridOption.gridManagerName,true);
        },
        fetch(){
            let data = {
                page:1,
                pageSize:100,
                deptId:tool.data.get('KEY_NEW_DEPT_ID'),
            }
            if(this.userName){
                data.userName = this.userName
            }
            if(this.RecruitmentChannels.length > 0){
                data.jobStateList = this.RecruitmentChannels
            }
            this.loading = true
            return new Promise((resolve, reject) => {
                newPostUrl.queryStoreManaegrOaUser(data).then((data)=>{
                    const tableData = {
                      data: data || [],
                      totalItem: data.length,
                    };
                    if(data.length > 0){
                      this.rowHanClick = data[0]
                      this.rowObj = data[0]
                      if(!data[0].userId){
                        this.dataState = true
                        return
                      }
                      this.detail(data[0])
                      if(this.menum==='1'){
                        this.rowObj = data[0]
                        this.$refs.flowTrackKey.fetchList(data[0].userId)
                      }
                      this.dataState = false
                    }else{
                      this.dataState = true
                    }
                    this.loading = false
                    resolve(tableData)
                })
                .catch(error=>{
                    this.dataState = true
                    this.loading = false
                    this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
                    reject(error)
                })
            });

        },
        menubarClick(e){
          this.menum = e
          if(!this.dataState){
            this.detail(this.rowHanClick)
          }
        },

        // 离职和离职签名图片预览
        closeViewer() {
          this.prevImage = false
          // this.showViewer = false
        },
        // 离职签名图片预览
        showViewerList(){
          this.imageList = []
          this.$nextTick(()=>{
              this.imageList.push(this.DimissionCheckForm.leaveAttachFile)
              this.prevImage = true
          })
        },
        // 确认离职
        confirmDimission(row){
            for (const key in this.dimissionForm) {
                this.dimissionForm[key] = ''
                }
            this.rows = row
            this.dimissionForm.leavePlanTime = moment(row.leavePlanTime).format('YYYY-MM-DD')
            this.showDimission = true
        },
        // 离职确认提交
        dimissionFormSubmit(){
            let data ={
                resignationRecordId:this.rows.resignationRecordId,
                finalLeaveTime:moment(this.dimissionForm.finalLeaveTime).format('YYYY-MM-DD'),
            }
            this.$refs.dimissionForm.validate((valid) => {
                if (valid) {
                    this.loading=true
                    newPostUrl.postUserResignationAgreementActions(data).then(res=>{
                        this.showDimission = false
                        this.$message.success('操作成功')
                        this.loading=false
                        GridManager.refreshGrid(this.gridOption.gridManagerName, true)
                    }).catch(err=>{
                        this.loading=false
                        this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
                    })
                }else{
                    return false
                }
            })
        },
        // 离职审核
        confirmDimissionCheck(row){
            for (const key in this.DimissionCheckForm) {
                    this.DimissionCheckForm[key] = ''
                }
            this.rows = row
            newPostUrl.queryUserResignationApplyRecordDetail({resignationRecordId:this.rows.resignationRecordId}).then(res=>{
                this.DimissionCheckForm.leavePlanTime = moment(res.leavePlanTime).format('YYYY-MM-DD')
                this.DimissionCheckForm.userReason = res.userReason
                this.DimissionCheckForm.leaveAttachFile = res.leaveAttachFile
                this.DimissionCheckForm.leaveReasonId = String(res.leaveReasonId)
            }).catch(err=>{
                this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
            })
            this.showDimissionCheck = true
        },
        // 撤销离职
        cancelDimission(row){
            this.$confirm("撤销后需要重新申请，确定要撤销？", {
              type: "warning",
            })
              .then(() => {
                let data ={
                    resignationRecordId:row.resignationRecordId
                }
                newPostUrl.reviewUserResignationRevocation(data).then(res=>{
                    this.$message.success("操作成功")
                    GridManager.refreshGrid(this.gridOption.gridManagerName, true)
                }).catch(err=>{
                    this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
                })
              })
            .catch(() => {});
        },
        // 不同意
        disagree(row){
            this.rows = row
            this.rejectDissmion = true
        },
        // 申请转正
        confirmApplyForFullMember(row){
            this.applyForFullMember = true
            this.rows = row
            newPostUrl.queryFrontendProbationUserDetail(
                {userId:row.userId}
            ).then(res=>{
              if(res.height){
                this.applyForFullMemberForm.height = res.height.toString()
              }
              if(res.weight){
                this.applyForFullMemberForm.weight = res.weight.toString()
              }
              if(res.dressSize){
                this.applyForFullMemberForm.dressSize = res.dressSize
              }
              if(res.weight){
                this.applyForFullMemberForm.politicsStatus = res.politicsStatus
              }
              if(res.weight){
                this.applyForFullMemberForm.liveAddress = res.liveAddress
              }
            }).catch(err=>{
              this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
            })

        },
        // 自离
        ifOwnDimission(row){
            this.rows = row
            this.ownDimission = true
            this.ownDimissionForm.cause = ''
            this.ownDimissionForm.probationDateEnd = moment(new Date()).format('YYYY-MM-DD')
        },
        // 劝退
        ifPersuadeSomebodyToQuit(row){
            this.rows = row
            this.persuadeSomebodyToQuit = true
            this.persuadeSomebodyToQuitForm.cause = ''
            this.persuadeSomebodyToQuitForm.probationDateEnd = moment(new Date()).format('YYYY-MM-DD')
        },
        // 行单击
        rowClick(row){
          if(!row.userId){
            this.dataState = true
            return
          }
          this.rowHanClick = row
          this.rowObj = row
          if(this.menum=='0'){
            this.detail(row)
          }
        },
        // 用户明细
        detail(row,e){
          this.loading= true
          if(row.jobState==='0' || row.jobState==='10'){
            newPostUrl.queryFrontendProbationUserDetail(
                {userId:row.userId}
            ).then(res=>{
              this.rowObj = res
              this.rowObj.tryShow = true
              this.loading = false
              if(e){
                this.$message({ type: 'success', message: '已刷新', duration: 2000, showClose: true })
              }
            }).catch(err=>{
              this.loading = false
              this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
            })
          }else{
            newPostUrl.QueryStoreManagerOaFrontendUserDetail(
                {userId:row.userId}
            ).then(res=>{
              this.rowObj = res
              this.rowObj.tryShow = false
              this.loading = false
              if(e){
                this.$message({ type: 'success', message: '已刷新', duration: 2000, showClose: true })
              }
            }).catch(err=>{
              this.loading = false
              this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
            })
          }
        },
        // 试工自离劝退
        submitOwnDimission(e){
            let that = null
            let reqData ={}
            if(e=='-12'){
                that = this.$refs.ownDimissionForm
                reqData.onboardingApplyRecordId = this.rows.onboardingApplyRecordId
                reqData.userId = this.rows.userId
                reqData.reason = this.ownDimissionForm.cause
                reqData.probationDateEnd = moment(this.ownDimissionForm.probationDateEnd).format('YYYY-MM-DD')
            }else if(e=='-10'){
                that = this.$refs.persuadeSomebodyToQuitForm
                reqData.onboardingApplyRecordId = this.rows.onboardingApplyRecordId
                reqData.userId = this.rows.userId
                reqData.reason = this.persuadeSomebodyToQuitForm.cause
                reqData.probationDateEnd = moment(this.persuadeSomebodyToQuitForm.probationDateEnd).format('YYYY-MM-DD')
            }
            reqData.trialState = e
            that.validate((valid) => {
                if (valid) {
                    this.$confirm("确定要进行此操作吗？", {
                        type: "warning",
                    })
                    .then(() => {
                        this.loading=true
                        newPostUrl.hrReviewUserTrialWorkRefuse(reqData).then(res=>{
                            this.$message.success('操作成功')
                            this.rows = null
                            this.ownDimission = false
                            this.persuadeSomebodyToQuit = false
                            this.loading=false
                            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
                        }).catch(err=>{
                            this.$message({ type: 'error', message: err.msg || err, duration: 2000, showClose: true })
                            this.loading=false
                            console.log(err)
                        })
                    })
                    .catch(() => {});
                } else {
                    return false;
                }
           });
        },
        determineClose(){
            this.applyForFullMember = false;
            for (const key in this.applyForFullMemberForm) {
                if(key=='entryAttachFile'){
                    this.applyForFullMemberForm.entryAttachFile = []
                    this.fileListImg = []
                }else{
                    this.applyForFullMemberForm[key]=''
                }
            }
        },
        // 申请转正
        submitApplyForFullMember(){
            this.$refs.applyForFullMemberForm.validate((valid) => {
                let form = {}
                for (const key in this.applyForFullMemberForm) {
                    if(key=='entryAttachFile'){
                        form[key] = this.applyForFullMemberForm[key][0]
                    }else if(key=='hiredDate'){
                        form[key] = moment(this.applyForFullMemberForm[key]).format('YYYY-MM-DD')
                    }else{
                        form[key] = this.applyForFullMemberForm[key]
                    }
                }
                form.onboardingApplyRecordId = this.rows.onboardingApplyRecordId
                form.userId = this.rows.userId
                form.trialState = '1'
                if (valid) {
                    this.$confirm("确定要进行此操作吗？", {
                        type: "warning",
                    })
                    .then(() => {
                        this.loading=true
                        newPostUrl.hrReviewUserTrialWorkAgree(form).then(res=>{
                            this.$message.success('提交成功')
                            this.rows = null
                            this.determineClose()
                            this.loading=false
                            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
                        }).catch(error=>{
                            this.loading=false
                            this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
                            console.log(error)
                        })
                    })
                    .catch(() => {});
                } else {
                    return false;
                }
           });
        },
        // 离职审核通过
        submitDimission(){
            let form = {
                resignationRecordId:this.rows.resignationRecordId,
            }
            for (const key in this.DimissionCheckForm) {
                form[key] = this.DimissionCheckForm[key]
            }
            if(!form.performanceImportance){return this.$message.error('业绩重视度不能为空')}
            if(!form.cooperationLevel){return this.$message.error('工作配合度不能为空')}
            if(!form.isEliminate){return this.$message.error('优胜劣汰不能为空')}
            form.leavePlanTime = moment(form.leavePlanTime).format('YYYYMMDD')

            this.$confirm("确定要进行此操作吗？", {
                type: "warning",
            })
            .then(() => {
                this.loading=true
                newPostUrl.reviewUserResignationAgreeByStoreManager(form).then(res=>{
                    this.$message.success('提交成功')
                    this.rows = null
                    this.showDimissionCheck = false
                    this.loading=false
                    GridManager.refreshGrid(this.gridOption.gridManagerName, true)
                }).catch(error=>{
                    this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
                    this.loading=false
                })
            })
            .catch(() => {});
        },
        // 不同意离职
        reject(){
            newPostUrl.reviewUserResignationRejectByStoreManager({resignationRecordId:this.rows.resignationRecordId}).then(res=>{
                this.$message.success('拒绝成功')
                this.rows = null
                this.rejectDissmion = false
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }).catch(error=>{
                this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
            })
        },

        imgUploadSuccess(res) {
            if (res.code == 200) {
              this.$message.success("上传成功");
              const data = JSON.parse(res.data);
              console.log('data',data.url)
              if (this.applyForFullMemberForm.entryAttachFile.indexOf(data.url) == -1) {
                this.applyForFullMemberForm.entryAttachFile.push(data.url);
                this.fileListImg.push({url:data.url})
              }
            } else {
              this.$message({ type: 'error', message: res.msg, duration: 2000, showClose: true })
              this.fileListImg = []
              this.applyForFullMemberForm.entryAttachFile = []
            }
        },
        limitNumb() {
          this.$message({ type: 'error', message: "超出上传数量", duration: 2000, showClose: true })
        },
        removeImg(file) {
            console.log('222',file)
            this.fileListImg.splice(
              this.fileListImg.findIndex((item) => item.url === file.url),
              1
            );
            this.applyForFullMemberForm.entryAttachFile.splice(
               this.applyForFullMemberForm.entryAttachFile.findIndex((item) => item === file.url),
               1
             );
        },
        handlePictureCardPreview() {
          this.prevImage = true;
          this.imageList.push(this.applyForFullMemberForm.entryAttachFile)
        },
        handleClose(){
          this.ownDimission = false;
          this.ownDimissionForm.cause = ''
          this.persuadeSomebodyToQuit = false;
          this.persuadeSomebodyToQuitForm.cause = ''
          this.rejectDissmion = false;
          this.rows = null
          this.showDimissionCheck = false
          for (const key in this.DimissionCheckForm) {
              this.DimissionCheckForm[key]=''
          }
          this.showDimission = false
          this.determineClose()
        },

    },
  };
  </script>
  
<style lang='scss' scoped>
.clickTop{
    height:40px;
    line-height: 40px;
    font-size: 14px;
    width:100px;
    text-align: center;
}
.clickTopActive{
    border-bottom: 3px solid #1684fc;
    color:#1684fc;
}
.dataStateStyle{
  display: flex;
  justify-content:center;
  align-items: center;
  background-color: #fff;
  height:82vh;
  width:100%;
  color:#ccc;
  font-size:30px;
}
  </style>