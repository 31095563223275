<template>
    <el-dialog
        width="1800px"
        title="应聘记录"
        :visible.sync="visibleShow"
        :close-on-click-modal="false"
        center
        :before-close="close"
    >
<!--        <span slot="close" class="el-icon-close" @click="close"></span>-->
        <div class="print-view">
            <div style="display:flex;padding-left:20px;">
                <span style="margin:5px 0px 0px 10px;">姓名：</span><el-input size="mini" clearable v-model="userName" style="width:180px;"></el-input>
                <span style="margin:5px 0px 0px 10px;">状态：</span>
                <el-select size="mini" clearable v-model="state" style=" width:180px;">
                        <el-option label="已处理" value="1"></el-option>
                        <el-option label="未处理" value="0"></el-option>

                </el-select>
                <el-button size="mini" style="margin-left:15px;" :loading="loading" type="primary" @click="fetchTwo()" >查询</el-button>
            </div>
            <div style="padding:20px;">
                <grid-manager :option="gridOption"></grid-manager>
                <el-dialog
                    v-if="visPro"
                    width="25%"
                    title="录用"
                    :visible.sync="visProFixed"
                    center
                    :close-on-click-modal="false"
                    :before-close="cancel"
                    :append-to-body="true" >
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" >
                        <el-form-item label="是否录用：" prop="ifState" >
                            <el-select  v-model="ruleForm.ifState" style="width: 300px" >
                               <el-option label="否" value="0"></el-option>
                               <el-option label="是" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="原因：" prop="description">
                            <el-input type="textarea" v-model="ruleForm.description" rows="4" style="width: 300px" ></el-input>
                        </el-form-item>
                        <div style="display:flex;margin-left:150px;margin-bottom:10px;">
                            <el-button size="mini" style="margin-left:15px;"  type="primary" @click="submit('ruleForm')" >确认</el-button>
                            <el-button size="mini" style="margin-left:15px;" @click="cancel()" >取消</el-button>
                        </div>
                    </el-form>
                </el-dialog>
            </div>
        </div>
        
    </el-dialog>
</template>

<script>

import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
import dialogModule from '@/components/dialogModule.vue'
import newPostUrl from '@/libs/http/modules/oa.js'
import tool from '@/utils/tool.js'

export default {
    components: {
        dialogModule
    },
    props: {},
    data() {
        return {
          visibleShow:true,
          loading:false,
          rows:{},
          visPro:false,
          visProFixed:true,
          employ:false,
          title:'应聘记录',
          state:'',
          userName:'',
          ruleForm:{
            ifState:'',
            description:'',
          },
          gridOption: {
            gridManagerName: 'ApplicationRecordGrid',
            firstLoading: true,
            isCombSorting: true,
            supportCheckbox: false,
            supportAutoOrder: false,
            useTrFocus: true,
            lineHeight: '8px',
            pageSize: 100,
            sizeData: [100, 200, 500, 1000],
            columnData: [
              { key: 'userName', text: '姓名',align:'center',width:'100px'},
              { key: 'sex', text: '性别' ,align:'center',width:'80px'},
              { key: 'phone', text: '手机号' ,align:'center',width:'130px'},
              { key: 'stateDesc', text: '状态',align:'center',width:'80px'},
              { key: 'education', text: '学历' ,align:'center',width:'80px'},
              { key: 'post', text: '求职岗位' ,align:'center',width:'130px'},
              { key: 'height', text: '身高' ,align:'center',width:'80px'},
              { key: 'weight', text: '体重' ,align:'center',width:'80px'},
              { key: 'age', text: '年龄' ,align:'center',width:'80px'},
              { key: 'maritalStatus', text: '婚姻状态' ,align:'center',width:'100px'},
              { key: 'halfLengthPhoto', text: '图片', width:'75px',align:'center',
                template:()=>{
                  return `
                              <el-image
                                v-if="row.halfLengthPhoto"
                                style="width: 50px; height: 25px"
                                :src="row.halfLengthPhoto"
                                :preview-src-list="[row.halfLengthPhoto]">查看图片
                              </el-image>
                              <div v-if='!row.halfLengthPhoto'>暂无图片</div>
                              `
                }
              },
              { key: 'previousJobDateRange', text: '工作时间',width:'200px',align:'center'},
              { key: 'previousJobPosition', text: '上一份岗位' ,align:'center',width:'130px'},
              { key: 'liveAddress', text: '现居住地址' ,align:'center',width:'150px'},
              { key: 'previousJobCompanyName', text: '工作单位名称' ,align:'center',width:'150px'},
              { key: 'reasonForLeavingPreviousJob', text: '离职原因' ,align:'center',width:'150px'},
              { key: 'isEmployedDesc', text: '是否录用' ,align:'center',width:'100px'},
              { key: 'description', text: '原因' ,align:'center',width:'150px'},
              { key: 'updateTime', text: '处理时间',width:'180px',align:'center'},
              {
                key: 'operate',
                text: '操作',
                align:'center',
                width: '150px',
                fixed: 'right',
                template: () => {
                  return `<el-button v-if="row.state == '0'" type="text" @click="showDia(row)">处理</el-button>`
                }
              },
            ],
            ajaxData: this.fetch,
            supportAjaxPage: true,
            dataKey: 'data',
            totalsKey: 'totalItem',
            height: '60vh'
          },
          rules:{
            ifState: [
              { required: true, message: '请选择', trigger: 'blur' },
            ],
            description: [
              { required: true, message: '请输入原因', trigger: 'blur' },
            ],
          }
        }
    },
  
    methods: {
        handleClose(){},
        close() {
            this.$emit('close')
        },
        fetchTwo(){
          GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        fetch(){
            let data={
                page:1,
                pageSize:100,
                marketId:tool.data.get('KEY_NEW_DEPT_ID'),
            }
            if(this.userName){
                data.userName = this.userName
            }
            if(this.state){
                data.state = this.state
            }
            this.loading=true
            return new Promise((resolve, reject) => {
                newPostUrl.queryOaUserApplyJobRecordList(data).then((res)=>{
                    const tableData = {
                      data: res.records,
                      totalItem: res.records.length,
                    };
                    console.log(tableData)
                    this.loading=false
                    resolve(tableData);
                })
                .catch((err)=>{
                    this.loading=false
                    console.log(err)
                    reject()
                })
            });
        },
        showDia(row){
            this.visPro = true
            this.rows = row
        },
        submit(formName){
          this.$refs[formName].validate((valid) => {
            if (valid){
              this.submitTwo();
            }else{
              return
            }
          });
        },
        submitTwo(){
          let data ={
            id:this.rows.id,
            isEmployed:this.ruleForm.ifState,
            description:this.ruleForm.description
          }
          newPostUrl.reviewOaUserApplyJobRecord(data).then(res=>{
            this.cancel()
            this.$message.success('操作成功')
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
          }).catch(err=>{
            console.log(err)
            this.$message.error(err)
          })
        },
        cancel(){
          this.visPro = false
          this.ruleForm={
            ifState:'',
            description:'',
          }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-icon-close {
    color: #999;
    padding: 10px;
    margin-right: -10px;
    cursor: pointer;
}
.print-view {
    padding-bottom: 5px;
    .webview {
        height: 100%;
        width: 100%;
    }
    .description {
        color: #067cf2;
        font-size: 16px;
        text-align: center;
        div {
            color: #666;
            margin-top: 5px;
        }
    }
    .finish-btn {
        width: 250px;
        height: 50px;
        background: #067cf2;
        border-radius: 4px;
        font-size: 20px;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        margin: 10px auto;
        cursor: pointer;
        span {
            font-size: 14px;
        }
    }
}
.btn-box{
    background-color:#ffffff; 
    border-radius:10%;
    width:300px;
    height:300px;
    border:2px solid #c6c6c6; 
    text-align:center; 
    position:absolute; 
    top:200px; 
    left:700px;
}
</style>
